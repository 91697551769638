import React from 'react';
import styled from 'styled-components';
import { colors } from '../styles/utilities';

const Sidebar = props => (
  <aside className={props.className}>
    <h3>Contact Information</h3>
    <h4>Phone</h4>
    <p className="phone">
      <a 
        className="phone ga-on-click-and-enter"
        href={`tel:${props.phoneNumber}`}
        ga='[
          "event", "click", {
            "event_category": "call",
            "event_label": "call-contact-page"
          }
        ]'
      >
        {props.phoneNumber}
      </a>
    </p>
    <h4>Email</h4>
    <p>
      <a className="email" href="mailto:tilleeyecare@gmail.com">tilleeyecare@gmail.com</a>
    </p>
    <h4>Address</h4>
    <p>
      3501 South Tamiami Trail<br />
      Suite 302<br />
      Sarasota, FL 34239
    </p>
    <a 
      className="btn btn--purple ga-on-click-and-enter"
      href="https://www.lenscrafters.com/ScheduleExamView?catalogId=11651&clearExams=1&langId=-1&storeNumber=0295&storeId=10851&cid=Yext_PagesSchedule_LCOD0295" 
      target="_blank"
      rel="noopener noreferrer"
      ga='[
        "event", "click", {
          "event_category": "appointment",
          "event_label": "appointment-contact-page"
        }
      ]'
    >See an Optometrist</a>
  </aside>
)

export default styled(Sidebar)`
  border: 1px solid ${colors.purpleDark};
  border-radius: 1px;
  padding: 30px 15px;
  h3 {
    margin-bottom: 15px;
  }
  h4 {
    font-size: 1.2rem;
    border-bottom: 1px solid ${colors.purpleDark};
  }
  a {
    color: #fff;
  }
  .phone, .email {
    color: ${colors.purpleDark};
  }
  font-size: 1rem;
`;